<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent
          class="py-8"
          title="In-Process Impact Test Standardization"
          size="large"
        />

        <p class="font-bold">
          The
          <router-link class="link" to="/mbom-impact-test"
            >MBOM Impact Test</router-link
          >
          is an in-process test as it provides results in engineering units that
          can readily be compared to in-process scenarios. Standardized
          procedures for the Verification of Standardization (VoS) or Round
          Robin testing are below. There are also standard procedures on how to
          apply the surface finish as well as how to determine a reaction.
          Results of testing to date are also presented with statistical
          analysis.
        </p>

        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              VERIFICATION OF STANDARDIZATION
            </h2>

            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              Testing can be completed by any lab to verify standardization
              efforts (procedures, calibration, material, etc.). The steps below
              further detail completion of the verification or Round Robin
              testing.
            </p>

            <ol class="p-light list-decimal pl-10 mt-4">
              <li>Download and implement the above detailed procedures,</li>
              <li>Review the Sample Application Video below,</li>
              <li>
                Obtain the 4-micron HMX (sample details
                <router-link
                  class="link"
                  to="/standardization-and-verification/hmx-sample-details"
                  >here</router-link
                >), application template(s), and/or chamber(s) as applicable,
              </li>
              <li>
                Complete testing according to the detailed instructions in the
                respective standard Round Robin/VoS procedure (link at right
                under 'Standardized Procedures', if not shown, request access
                <router-link
                  style="color:#ef9a3d;"
                  to="/standardization-and-verification/round-robin-participation"
                  >here</router-link
                >), and
              </li>
              <li>
                Share your results with the ET Users Group community. You can
                <router-link class="link" to="/contact-us/"
                  >contact us here</router-link
                >.
              </li>
            </ol>

            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ET Users Group Result Using High-Speed Video (To Date)
            </h2>

            <p class="p-light">
              The plot below depict the Round Robin/VoS result for MBOM Impact
              (per the above standardized procedure for HSV and
              <a
                class="link"
                target="_blank"
                href="https://godetect.smsenergetics.com/"
                >GoDetect™</a
              >) with the average distribution indicated by a dotted line. A
              statistical cutoff for the data summary was established using a
              95% confidence level, which corresponds to a 5% false positive
              rate.
            </p>

            <a
              :href="require('../../assets/MBOM-Impact-HSV-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/MBOM-Impact-HSV-min.png" />
            </a>

            <p
              class="mt-6"
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              ET Users Group Result (lab average) for 4-micron HMX MBOM Impact
              log-logistic values (uln, sln) for comparison with the SRC Method:
              (3.07, 0.456) .
            </p>
            <p
              class="p-light mt-6"
              v-if="$store.getters['user/userClasses']['role-impact-test']"
            >
              Further details of the results are documented in the summary
              presentation
              <a
                class="link"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/HMXRoundRobinResultsSummary.pdf?alt=media&token=edf22243-bf1f-460a-b794-06e2fcfc7518"
                >here</a
              >.
            </p>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <div
                v-if="$store.getters['user/userClasses']['role-impact-test']"
              >
                <h2
                  class="mb-6"
                  style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
                >
                  STANDARDIZED PROCEDURES
                </h2>
                <div class="mb-6 p-light">
                  <ul class="pl-10 list-decimal">
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/A029-ETUG-ProcedureforModifiedBOMImpactTestRev2.pdf?alt=media&token=c300f1f9-92ad-412b-9762-bd1db2db84e6"
                        target="_blank"
                        rel="noreferrer noopener"
                        >MBOM Impact Test Procedure</a
                      >
                      (General)
                    </li>
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/MBOMImpactRRProcedure(HSV).pdf?alt=media&token=f428ec4c-fe91-4beb-b5b6-39c3130e9b40"
                        target="_blank"
                        rel="noreferrer noopener"
                        >MBOM Impact Round-Robin Procedure: High-speed Video
                        (HSV)
                      </a>
                    </li>
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/MBOMImpactRRProcedure(GA).pdf?alt=media&token=60e9d450-e75d-4329-9f91-18a3d4e081b7"
                        target="_blank"
                        rel="noreferrer noopener"
                        >MBOM Impact Round-Robin Procedure: Gas Analysis (GA)
                      </a>
                    </li>
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/5f-ImpactInsertSurfacingProcedure.pdf?alt=media&token=d5b0b2df-c871-42f2-9ec8-7ba6b6ca82be"
                        target="_blank"
                        rel="noreferrer noopener"
                        >MBOM Impact Insert Surface Refinishing Procedure</a
                      >
                    </li>
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/5h-ImpactAnvilSurfacingProcedure.pdf?alt=media&token=9f57d131-6f70-46b0-8cd6-ac8225979f0c"
                        target="_blank"
                        rel="noreferrer noopener"
                        >MBOM Impact Anvil Surface Refinishing Procedure</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                SUPPORT DOCUMENTS AND VIDEOS
              </h2>
              <ul class="p-light list-disc pl-10">
                <li>
                  <a
                    class="link"
                    href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ImpactGasAnalyzerChamber.pdf?alt=media&token=b77109f9-60b7-486c-ba42-c47e1e412bee"
                    >Impact Gas Analyzer Chamber Drawings</a
                  >
                </li>
                <li>
                  <a
                    class="link"
                    href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/Impact-ESD%20Sample%20Locator.pdf?alt=media&token=061c4f9e-3ebe-43aa-9794-470a0f238e67"
                    >Impact Sample Application Template Drawing</a
                  >
                </li>
                <li>
                  Videos of reaction detection with high-speed video are
                  <router-link class="link" to="/reaction-detection-discussion"
                    >here</router-link
                  >
                </li>
              </ul>
            </div>

            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              SAMPLE APPLICATION VIDEO
            </h2>
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/126550779?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo126550779"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <h2
              class="my-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Statistics
            </h2>
            <p class="p-light">
              A significant part of any VoS or Round Robin effort is determining
              the criteria that defines agreement or disagreement between test
              facilities. It’s highly unlikely that the exact same parameters
              defining the reaction transition for a given material and test
              machine will be obtained by all the sites even if they were done
              exactly the same way. As such, it is likely that the results from
              each laboratory or within the same laboratory will differ. The
              criteria to say that the results are different or not should be
              statistically based. The ET Users Group has adopted the SRC Method
              or the
              <a
                class="link"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/StatisticalRelativeComparisonMethod.pdf?alt=media&token=fd5b8bc1-c5de-4c4c-b12b-77bbf892e6ac"
                >Statistical Relative Comparison (SRC) Method</a
              >
              to determine if differences in the sensitivity test outcomes are
              indeed significantly different from the ET Users Group community
              given above.
            </p>
          </div>
          <div class="w-full">
            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Feedback
            </h2>
            <p class="p-light">
              If you have any feedback on the information found here or have
              completed testing please share your feedback and/or results with
              us
              <router-link class="link" to="/contact-us"
                >(Contact Us)</router-link
              >.
            </p>
          </div>
        </div>
        <div class="w-3/4 m-auto text-left mt-12 text-md">
          <p class="leading-relaxed" style="color:#7e8890;">
            Terms of Use
            <br />
            All material on this website is copyrighted by the ET Users Group™.
            By accessing the content in the Test Methods Matrix™ you agree to
            the following:
          </p>
        </div>
        <div
          style="color:#7e8890;"
          class="w-3/4 pl-16 md:pl-32 text-left ml-10 mt-4 text-md"
        >
          <ul class="list-disc">
            <li class="mt-2">
              You will not make copies of this material or otherwise make this
              content available offline for others (such as but not limited to
              creating training materials) without written consent from the ET
              Users Group;
            </li>
            <li class="mt-2">
              You will direct inquiries regarding access to this content to the
              ET Users Group;
            </li>
            <li class="mt-2">
              You will not hold liable the ET Users Group or any associated
              entity for damages caused by the use or misuse of this content.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss"></style>
